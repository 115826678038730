const mainState = {

  login_user: {
    _id: '',
    name: '',
    email: '',
    role: '',
    __v: 4
  }

}

export { mainState }
