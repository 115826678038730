import Vue from 'vue'
import Vuex from 'vuex'


import { mainState } from './modules/mainapp/state'
import { mainGetters } from './modules/mainapp/getters'
import { mainMutations } from './modules/mainapp/mutations'
import { mainActions } from './modules/mainapp/actions'

import { cartState } from './modules/cart/state'
import { cartGetters } from './modules/cart/getters'
import { cartMutations } from './modules/cart/mutations'
import { cartActions } from './modules/cart/actions'

Vue.use(Vuex)


const main = {
  state: mainState,
  getters: mainGetters,
  mutations: mainMutations,
  actions: mainActions
}

const event = {
  state: cartState,
  getters: cartGetters,
  mutations: cartMutations,
  actions: cartActions
}

export default new Vuex.Store({
  modules: {
    main: main,
    event : event
  }

})
