<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo_meals.png"> +
    <img alt="Vue logo" src="../assets/logo-logomark.png">
    <HelloWorld msg="Welcome"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
