<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <div class="table">
      <b-table striped hover 	 :items="items" :fields="fields" :busy="loading">
        <template #table-busy>
          <div class="text-center my-2 text-style">
            <b-spinner class="align-middle">
            </b-spinner>
          </div>
        </template>

        <template #cell(action)="row">
          <b-button size="sm" @click="viewFile('ios', row)" class="mr-2">
            View ios file
          </b-button>
          <div class="mobile_view">
            <br>
          </div>


          <b-button size="sm" @click="viewFile('android', row)" class="mr-2">
            View android file
          </b-button>
          <div class="mobile_view">
            <br>
          </div>
          <b-button size="sm" @click="viewFile('firebase', row)" class="mr-2">
            View firebase file
          </b-button>
        </template>

        <template #cell(delete)="row">
          <b-button variant="danger" size="sm" @click="delete_domain( row)" class="mr-2">
            Delete
          </b-button>

        </template>


      </b-table>
    </div>

    <div class="fab-container">
      <div class="button iconbutton" @click="add_click()">
        <i class="fa-solid fa-plus"></i>
      </div>
    </div>


    <b-modal id="modal-center-event"
             no-close-on-backdrop
             hide-footer
             centered title="New event">

      <div class="events_container_">

        <div>
          <b-form-input v-model="host_name"
                        placeholder="Host address"
                        :disabled=" ios_file_uploaded || android_file_uploaded || firebase_file_uploaded "
          ></b-form-input>
        </div>

        <br>

          <b-form-file
              accept=".json"
              v-model="android_file"
              :state="android_file_uploaded"
              ref="android_file"
              placeholder="Please upload Android config file"
              drop-placeholder="Drop file here..."
              v-on:change="handleFileUpload('android_file')"

          ></b-form-file>

        <br>
        <br>

         <b-form-file
             accept=".plist"
             v-model="ios_file"
              :state="ios_file_uploaded"
              ref="ios_file"
              placeholder="Please upload IOS plist"
              drop-placeholder="Drop file here..."
              v-on:change="handleFileUpload('ios_file')"

          ></b-form-file>
        <br>
        <br>


        <b-form-file
            accept=".json"
            v-model="firebase_file"
              :state="firebase_file_uploaded"
              ref="firebase_file"
              placeholder="Please upload service account here"
              drop-placeholder="Drop file here..."
              v-on:change="handleFileUpload('firebase_file')"
          ></b-form-file>
        <br>
        <br>


        <b-button type="submit" variant="primary" @click="addApp()" :disabled="loading">Submit</b-button>
      </div>

    </b-modal>


<!--    <div  class="center_">-->
<!--      <fade-loader v-if="!$auth.ready() || loading" color="#FFB52E" size="19px" style="text-align: center"/>-->
<!--    </div>-->

  </div>
</template>

<script>
import { variables } from "./../constants/systemConstants";
import axios from "axios";
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      android_file : undefined,
      android_file_uploaded : false,
      ios_file : undefined,
      ios_file_uploaded : false,
      firebase_file : undefined,
      firebase_file_uploaded : false,
      loading : false,



      host_name : "",

      fields: [{
        key: 'host_name',
        label : 'Application',
        sortable: true
      },
        {
          key: 'action',
          label : 'Action',
          sortable: false
        },
        {
          key: 'delete',
          label : 'Delete',
          sortable: false
        },
        ],
      items: [
      ]
    }
  },

  beforeMount() {
    this.getData();
  },
  methods : {
    getData(){
      let app = this
      app.loading = true
      axios
          .post("/auth/get_domains")
          .then((res) => {

            let hostnames = []
            let all_domains = res.data.domains
            for(let i = 0; i < all_domains.length; i++){
              hostnames.push({host_name : all_domains[i].domain_name})
            }

            app.items = hostnames
            app.loading = false

          })
          .catch(() => {
            app.loading = false
            app.makeToast(
                variables.danger,
                "Unable to load domains",
                variables.danger
            );
          });

    },
    add_click () {
      this.$root.$emit('bv::show::modal', 'modal-center-event', '#btnShow')
    },
    handleFileUpload (file) {

      let app = this
      if(app.host_name === "")
      {
        this.makeToast(variables.warning,
            "Please add a value to host_name",
            variables.success)
        return
      }

      for(let i = 0; i < this.items.length; i++) {
        if(this.items[i].host_name === app.host_name)
        {
          this.makeToast(variables.warning,
              "Host name already exists",
              variables.success)
          return
        }
      }

        setTimeout(()=> {

        let file_to_send = undefined
        if(file === 'ios_file')
        {
          file_to_send = app.ios_file
        }
        else if(file === 'firebase_file')
        {
          file_to_send = app.firebase_file
        }
        else if(file === 'android_file')
        {

          file_to_send =  app.android_file
        }
        app.submitFile(file_to_send)
      }, 200)

    },
    submitFile(file) {

      let formData = new FormData();
      /*
          Add the form data we need to submit
      */
      formData.append("file", file);
      formData.append("domain_name", this.host_name);

      /*
        Make the request to the POST /single-file URL
      */

      let app = this;
      app.makeToast(
          variables.warning,
          "About to upload file",
          variables.success
      );

      axios
          .post("/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(function(res) {


            console.log(file)
            console.log(app.android_file)

            if(file === app.ios_file)
            {
              app.ios_file_uploaded = true
            }
            else if(file === app.firebase_file)
            {
              app.firebase_file_uploaded = true
            }
            else if(file === app.android_file)
            {
              console.log("HERE")
              app.android_file_uploaded = true
            }

            res
            // console.log(res.data.file);
            app.makeToast(variables.success, "File uploaded", variables.success);
          })
          .catch(function() {
            app.makeToast(variables.danger, "Unable to upload file", variables.danger);
          });
    },


    addApp(){


      if(this.host_name && this.firebase_file_uploaded && this.ios_file_uploaded && this.android_file_uploaded) {


        let data = {
          "domain_name": this.host_name,
          "firebase_file": this.firebase_file.name,
          "android_file": this.android_file.name,
          "ios_file": this.ios_file.name
        }
        let app = this
        app.loading  = true
        axios
            .post("/auth/save_domain", data)
            .then(function () {
              app.loading = false

              app.android_file_uploaded = false
              app.ios_file_uploaded = false
              app.firebase_file_uploaded = false

              app.firebase_file = undefined
              app.ios_file = undefined
              app.android_file = undefined
              app.host_name = ""


              app.makeToast(variables.success, "Data saved", variables.success);
              app.$root.$emit('bv::hide::modal', 'modal-center-event', '#btnShow')

              app.getData()


            })
            .catch(function () {
              app.loading = false
              app.makeToast(variables.danger, "Unable to save data", variables.danger);
            });
      }
      else{
        this.makeToast(variables.danger, "Please complete all data", variables.danger);
      }
    },
    viewFile(file_name, row) {

      let domain_name = row.item.host_name


      let app = this
      app.loading = true

      axios
          .post("/auth/download_files", {domain_name})
          .then(function (res) {

            // console.log(res.data)
            let data = ""
            if(file_name === "ios"){
              data = res.data.data_ios_file
              data = "<xmp>" + data + "</xmp>"

            }
            else if(file_name === "android"){
              data = res.data.data_android_file
              data = "<pre>" + data + "</pre>"

            } else if(file_name === "firebase"){
              data = res.data.data_firebase_file

              data = "<pre>" + data + "</pre>"

            }

            let newWindow = window.open();
            newWindow.document.write("<pre>" + data + "</pre>");
            app.loading = false

          })
          .catch(function () {
            app.loading = false
            app.makeToast(variables.danger, "Unable to load data", variables.danger);
          });

    },
    delete_domain( row) {

      if(!confirm(reminder))
      {
        return false;
      }
      let domain_name = row.item.host_name

      let app = this
      app.loading = true

      let reminder = "Are you sure you want to delete this record?";

      // return
      axios
          .post("/auth/delete_domain", {domain_name})
          .then(function (res) {

            console.log(res)
            app.getData()
            app.loading = false

          })
          .catch(function () {
            app.loading = false
            app.makeToast(variables.danger, "Unable to delete data", variables.danger);
          });

    },
    makeToast(variant = null, data, title) {
      // @ts-ignore
      this.$bvToast.toast(data, {
        title: title,
        variant: variant,
        solid: true
      });
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #FF8A65;
}


.fab-container{
  position:fixed;
  bottom:50px;
  right:50px;
  cursor:pointer;
}

.iconbutton{
  width:50px;
  height:50px;
  border-radius: 100%;
  background: #FF4F79;
  box-shadow: 10px 10px 5px #aaaaaa;
}
.button{
  width:60px;
  height:60px;
  background:#FF8A65;
}
.iconbutton i{
  display:flex;
  align-items:center;
  justify-content:center;
  height: 100%;
  color:white;
}


@media screen and (max-width: 900px) and (min-width: 600px), (min-width: 1100px) {
  .table {
    padding-left: 25%;
    padding-right: 25%;
    width: 100%;
  }

  .mobile_view{
    display: none;
  }
}
</style>
